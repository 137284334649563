import Picker from 'better-picker'
import city from "./city";

import {
    ref,
    computed,
    onMounted,
    watch
} from 'vue'

const usePicker = (props, ctx) => {

    const select = ref(null)

    let picker = null;

    
    const data = [];

    

    if (Array.isArray(props.options[0])) {
        props.options.forEach(item => {
            data.push(item)
        });
    } else {
        data[0] = []
        props.options.forEach(item => {
            data[0].push(item)
        });
    }


    function setNativeSelect(selectedIndex) {
        // console.log("%c" + props.modelValue, "color:green");
        console.log(data, selectedIndex)
        let value = ''
        for(let i = 0;i < data[0].length;i++){
            if(data[0][i].value == selectedIndex[0]){
                value = data[0][i] && data[0][i].text || ''
            }
        }
        select.value.value = value;

    }


    //省辖市多选情况

    const first = []; /* 省，直辖市 */
    let second = []; /* 市 */
    let third = []; /* 镇 */

    const selectedIndex = [0, 0, 0]; /* 默认选中的地区 */

    const checked = [0, 0, 0]; /* 已选选项 */

    function creatList(obj, list) {
        obj.forEach(function (item, index, arr) {
            const temp = new Object();
            temp.text = item.name;
            temp.value = index;
            list.push(temp);
        })
    }
    

    if (!props.multiple) {
        

        const nativeInputValue = computed(() => {
            return props.modelValue === null || props.modelValue === undefined ?
                [] :
                String(props.modelValue).split(',');
        });

        console.log(nativeInputValue.value)

        picker = new Picker({
            data: data,
            selectedIndex: nativeInputValue.value,
            title: props.title
        });

        
        picker.on('picker.select', function (selectedVal, selectedIndex) {
            setNativeSelect(selectedVal)
        })

        picker.on('picker.change', function (index, selectedIndex) {
            // console.log(index);
            // console.log(selectedIndex);
        });

        picker.on('picker.valuechange', function (selectedVal, selectedIndex) {
            console.log(selectedVal);
            if (selectedVal.length > 1) {
                ctx.emit('update:modelValue', selectedVal.join(','))
            } else {
                ctx.emit('update:modelValue', selectedVal[0])
            }
        });

        onMounted(() => {
            setNativeSelect(nativeInputValue.value)
        })


    } else {
        

        creatList(city, first);


        

        if (city[selectedIndex[0]]['sub']) {
            creatList(city[selectedIndex[0]].sub, second);
        } else {
            second = [{
                text: '',
                value: 0
            }];
        }

        if (city[selectedIndex[0]].sub[selectedIndex[1]]['sub']) {
            creatList(city[selectedIndex[0]].sub[selectedIndex[1]].sub, third);
        } else {
            third = [{
                text: '',
                value: 0
            }];
        }

        watch(() => props.modelValue, () => {
            console.log(1323123123, props.modelValue)
            if(props.modelValue){
                select.value.value = String(props.modelValue);
            }
        });


        picker = new Picker({
            data: [first, second, third],
            selectedIndex: selectedIndex,
            title: '地址选择'
        });

        

        picker.on('picker.select', function (selectedVal, selectedIndex) {
            const text1 = first[selectedIndex[0]].text;
            const text2 = second[selectedIndex[1]].text;
            const text3 = third[selectedIndex[2]] ? third[selectedIndex[2]].text : '';
            select.value.value = text1 + ' ' + text2 + ' ' + text3;
        });



        picker.on('picker.change', function (index, selectedIndex) {
            function firstChange() {
                second = [];
                third = [];
                checked[0] = selectedIndex;
                const firstCity = city[selectedIndex];
                if (firstCity['sub']) {
                    creatList(firstCity.sub, second);

                    const secondCity = city[selectedIndex].sub[0]
                    if (secondCity['sub']) {
                        creatList(secondCity.sub, third);
                    } else {
                        third = [{
                            text: '',
                            value: 0
                        }];
                        checked[2] = 0;
                    }
                } else {
                    second = [{
                        text: '',
                        value: 0
                    }];
                    third = [{
                        text: '',
                        value: 0
                    }];
                    checked[1] = 0;
                    checked[2] = 0;
                }

                picker.refillColumn(1, second);
                picker.refillColumn(2, third);
                picker.scrollColumn(1, 0)
                picker.scrollColumn(2, 0)
            }

            function secondChange() {
                third = [];
                checked[1] = selectedIndex;
                const firstIndex = checked[0];
                if (city[firstIndex].sub[selectedIndex]['sub']) {
                    const secondCity = city[firstIndex].sub[selectedIndex];
                    creatList(secondCity.sub, third);
                    picker.refillColumn(2, third);
                    picker.scrollColumn(2, 0)
                } else {
                    third = [{
                        text: '',
                        value: 0
                    }];
                    checked[2] = 0;
                    picker.refillColumn(2, third);
                    picker.scrollColumn(2, 0)
                }
            }
            if (index === 0) {
                firstChange();
            } else if (index === 1) {
                secondChange();
            }



        });

        picker.on('picker.valuechange', function (selectedVal, selectedIndex) {
            const text1 = first[selectedIndex[0]].text;
            const text2 = second[selectedIndex[1]].text;
            const text3 = third[selectedIndex[2]] ? third[selectedIndex[2]].text : '';
            ctx.emit('update:modelValue', text1 + ' ' + text2 + ' ' + text3)
        });



    }



    function showPicker(params) {
        picker.show();
    }

    return {
        select,
        showPicker,
    }
}

export default usePicker