<template>
  <div class="form-item" @click="showPicker">
    <label :for="name">
      <div class="form-label" v-if="label">{{ label }}</div>
      <input ref="select" disabled :style="{ textAlign: textAlign }" />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, PropType } from "vue";
import usePicker from "./usePicker.js";
type AlignType = "left" | "center" | "right";
export default defineComponent({
  name: "Select",
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type:String  ,
      default: "",
    },
    modelSelectValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    textAlign: {
      type: String as PropType<AlignType>,
      default: "right",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { select, showPicker } = usePicker(props, ctx);

    return {
      select,
      showPicker,
    };
  },
});
</script>


<style lang="scss" scoped>
.form-item {
  border-bottom: 1px solid #eee;
  padding-left: 73.5px;
  position: relative;
  height: 45px;
  line-height: 45px;
  text-align: right;
  color: #444444;
  font-size: 13.988px;
  padding-bottom: 1px;
}

.form-item input {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;

  background: transparent;
}

.form-label {
  width: 80px;
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  line-height: 45px;
  text-align: left;
  padding-left: 10px;
}

.form-item-textarea {
  border-bottom: 1px solid #eee;
  padding-left: 66px;
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

.form-item-textarea label {
  height: 100%;
  display: inline-block;
  width: 100%;
}

.form-item-textarea textarea {
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial;
  margin-top: 10px;
}
</style>